export default {
  group: 'Availability of Instruction Manuals',
  key: 'instruction_manuals',
  chartGroup: 'Instruction Manuals',
  questions: [
    {
      key: 'disassembly_instructions',
      index: 0,
      label: 'Are there instructions for disassembly?',
      type: 'select',
      required: true,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'repair_manuals',
      index: 1,
      label: 'Are there Repair Manuals?',
      type: 'select',
      required: true,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
  ],
};
