export default {
  group: 'Power Specifications',
  key: 'power',
  chartGroup: 'Power',
  questions: [
    {
      key: 'load_10_percent',
      subgroup: 'single_output',
      index: 0,
      label: 'What is the minimum PSU efficiency at 10% single output?',
      required: true,
      type: 'select',
      options: [
        { value: '90_100', label: 'More than 90%' },
        { value: '90', label: '90%' },
        { value: '0', label: 'Less than 90%' },
      ],
    },
    {
      key: 'load_20_percent',
      subgroup: 'single_output',
      index: 1,
      label: 'What is the minimum PSU efficiency at 20% single output?',
      required: true,
      type: 'select',
      options: [
        { value: '94_100', label: 'More than 94%' },
        { value: '94', label: '94%' },
        { value: '0', label: 'Less than 94%' },
      ],
    },
    {
      key: 'load_50_percent',
      subgroup: 'single_output',
      index: 2,
      label: 'What is the minimum PSU efficiency at 50% single output?',
      required: true,
      type: 'select',
      options: [
        { value: '96_100', label: 'More than 96%' },
        { value: '96', label: '96%' },
        { value: '0', label: 'Less than 96%' },
      ],
    },
    {
      key: 'load_100_percent',
      subgroup: 'single_output',
      index: 3,
      label: 'What is the minimum PSU efficiency at 100% single output?',
      required: true,
      type: 'select',
      options: [
        { value: '91_100', label: 'More than 91%' },
        { value: '91', label: '91%' },
        { value: '0', label: 'Less than 91%' },
      ],
    },
    {
      key: 'min_power_factor',
      subgroup: 'single_output',
      index: 4,
      label: 'What is the minimum power factor at 50% single output?',
      required: true,
      type: 'select',
      options: [
        { value: '95_100', label: 'More than 0.95' },
        { value: '95', label: '0.95' },
        { value: '0', label: 'Less than 0.95' },
      ],
    },
    {
      key: 'load_20_percent',
      subgroup: 'multi_output',
      index: 5,
      label: 'What is the minimum PSU efficiency at 20% multi-output?',
      required: true,
      type: 'select',
      options: [
        { value: '90_100', label: 'More than 90%' },
        { value: '90', label: '90%' },
        { value: '0', label: 'Less than 90%' },
      ],
    },
    {
      key: 'load_50_percent',
      subgroup: 'multi_output',
      index: 6,
      label: 'What is the minimum PSU efficiency at 50% multi-output?',
      required: true,
      type: 'select',
      options: [
        { value: '94_100', label: 'More than 94%' },
        { value: '94', label: '94%' },
        { value: '0', label: 'Less than 94%' },
      ],
    },
    {
      key: 'load_100_percent',
      subgroup: 'multi_output',
      index: 7,
      label: 'What is the minimum PSU efficiency at 100% multi-output?',
      required: true,
      type: 'select',
      options: [
        { value: '91_100', label: 'More than 91%' },
        { value: '91', label: '91%' },
        { value: '0', label: 'Less than 91%' },
      ],
    },
    {
      key: 'min_power_factor',
      subgroup: 'multi_output',
      index: 8,
      label: 'What is the minimum power factor at 50% multi-output?',
      required: true,
      type: 'select',
      options: [
        { value: '95_100', label: 'More than 0.95' },
        { value: '95', label: '0.95' },
        { value: '0', label: 'Less than 0.95' },
      ],
    },
  ],
};
