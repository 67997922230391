export default {
  group: 'Design & Manufacture',
  key: 'design_and_manufacture',
  chartGroup: 'Design & Manufacture',
  questions: [
    {
      key: 'component_reuse_across_generations',
      label:
        'Can the following parts be used/re-used across different product generations?',
      required: false,
      type: 'radio',
      sub_questions: [
        {
          label: 'Data Storage',
          key: 'storage',
          index: 0,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Memory',
          key: 'memory',
          index: 1,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'CPU',
          key: 'cpu',
          index: 2,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Motherboard',
          key: 'motherboard',
          index: 3,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Expansion/Graphics Card',
          key: 'expansion',
          index: 4,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'PSU',
          key: 'psu',
          index: 5,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Chassis',
          key: 'chasis',
          index: 6,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Batteries',
          key: 'battery',
          index: 7,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Cooling Assemblies',
          key: 'cooling_assembly',
          index: 8,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
      ],
    },
    {
      key: 'component_reuse_across_models',
      label:
        'Can the following parts be used/re-used across different product models?',
      required: false,
      type: 'radio',
      sub_questions: [
        {
          label: 'Data Storage',
          key: 'storage',
          index: 9,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Memory',
          key: 'memory',
          index: 10,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'CPU',
          key: 'cpu',
          index: 11,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Motherboard',
          key: 'motherboard',
          index: 12,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Expansion/Graphics Card',
          key: 'expansion',
          index: 13,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'PSU',
          key: 'psu',
          index: 14,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Chassis',
          key: 'chasis',
          index: 15,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Batteries',
          key: 'battery',
          index: 16,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Cooling Assemblies',
          key: 'cooling_assembly',
          index: 17,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
      ],
    },
    {
      key: 'component_reuse_across_brands',
      required: false,
      type: 'select',
      index: 18,
      label: 'Can parts be used/re-used across different product brands?',
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'design_for_remanufacture',
      required: false,
      index: 19,
      label: 'Is the product designed for re-manufacture?',
      type: 'select',
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'premature_obsolescence',
      label: 'Is the product designed to become obsolete prematurely?',
      required: true,
      type: 'select',
      index: 20,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'mean_time_between_failure',
      required: false,
      index: 21,
      type: 'select',
      label: 'What is the mean time between failure?',
      options: [
        { value: 'more_than_10_years', label: 'More than 10 years' },
        { value: 'only_10_years', label: '10 years' },
        { value: '0', label: 'Less than 10 years' },
      ],
    },
    {
      key: 'reusability',
      required: false,
      label: 'Can the following parts be used for more than one lifetime?',
      type: 'radio',
      sub_questions: [
        {
          label: 'Data Storage',
          key: 'storage',
          index: 22,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Memory',
          key: 'memory',
          index: 23,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'CPU',
          key: 'cpu',
          index: 24,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Motherboard',
          key: 'motherboard',
          index: 25,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Expansion/Graphics Card',
          key: 'expansion',
          index: 26,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'PSU',
          key: 'psu',
          index: 27,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Chassis',
          key: 'chasis',
          index: 28,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Batteries',
          key: 'battery',
          index: 29,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Cooling Assemblies',
          key: 'cooling_assembly',
          index: 30,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
      ],
    },
    {
      key: 'upgradability',
      label:
        'Can the following parts be easily upgraded and re-used in newer generations, to extend their lifetime?',
      required: false,
      type: 'radio',
      sub_questions: [
        {
          label: 'Data Storage',
          key: 'storage',
          index: 31,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Memory',
          key: 'memory',
          index: 32,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'CPU',
          key: 'cpu',
          index: 33,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Motherboard',
          key: 'motherboard',
          index: 34,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Expansion/Graphics Card',
          key: 'expansion',
          index: 35,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'PSU',
          key: 'psu',
          index: 36,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Chassis',
          key: 'chasis',
          index: 37,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Batteries',
          key: 'battery',
          index: 38,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Cooling Assemblies',
          key: 'cooling_assembly',
          index: 39,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
      ],
    },
    // {
    //   key: 'product_as_service',
    //   required: false,
    //   type: 'select',
    //   index: 40,
    //   label: 'Can the product be used as a service?',
    //   options: [
    //     { value: 'yes', label: 'Yes' },
    //     { value: 'not_available', label: 'No' },
    //   ],
    // },
  ],
};
