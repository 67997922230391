<template>
  <apexchart
    type="radar"
    height="500"
    width="600"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  name: 'EvaluateChart',
  props: ['groups', 'data'],
  data() {
    return {
      series: [
        {
          name: 'Results',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 4,
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: ['#7CD37A', '#EDDC82', '#FFB99B', '#FF6666'],
              strokeWidth: 3,
            },
          },
        },
        colors: ['#48c2c5'],
      },
    };
  },
  created() {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        labels: {
          offsetY: 3,
        },
        categories: this.groups,
      },
    };
  },
  updated() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      const score = this.data.groupScore;
      const labels = this.chartOptions.xaxis.categories;
      const group = (value) => value === this.data.chartGroup;
      const groupIndex = labels.findIndex(group);
      this.series[0].data[groupIndex] = score;
    },
  },
};
</script>

<style lang="scss">
.apexcharts-datalabel {
  font-size: 13px;
  font-family: $default-font !important;
  font-weight: 500;
  color: $default;
}

.apexcharts-yaxis {
  text.apexcharts-text {
    font-family: $default-font;
  }
}
</style>
