export default {
  group: 'Security',
  key: 'security',
  chartGroup: 'Security',
  questions: [
    {
      key: 'secure_data_sanitisation',
      label: 'Is secure data sanitisation available? ',
      required: true,
      index: 0,
      type: 'select',
      options: [
        { value: 'embedded', label: 'Yes - Embedded' },
        { value: 'downloadable', label: 'Yes - Downloadable' },
        { value: '0', label: 'No' },
      ],
    },
    {
      key: 'data_deletion_tool_end_of_sale',
      index: 1,
      label:
        'What is the availability of a secure integral data deletion tool after the end of sale?',
      required: true,
      type: 'select',
      options: [
        { value: 'more_than_8_years', label: 'More than 8 years' },
        { value: 'only_8_years', label: '8 years' },
        { value: 'less_than_8_years', label: 'Less than 8 years' },
      ],
    },
  ],
};
