<template>
  <div class="score-wrapper">
    <div class="score-value" :style="`margin-left: ${valuePosition}`">
      <p>{{ roundedValue }}</p>
      <span>Overall Score</span>
      <div class="score-triangle"></div>
    </div>
    <span class="score score-0">0%</span>
    <span class="score score-50">50%</span>
    <span class="score score-100">100%</span>
    <div class="score-container"></div>
    <span class="score-text score-text-0">Not circular</span>
    <span class="score-text score-text-100">Circular</span>
  </div>
</template>

<script>
export default {
  name: 'OverallScore',
  props: ['value'],
  data() {
    return {
      valuePosition: '0%',
      roundedValue: null,
    };
  },
  created() {
    this.setValuePosition();
  },
  watch: {
    value() {
      this.setValuePosition();
    },
  },
  methods: {
    setValuePosition() {
      if (this.value === 0) {
        this.valuePosition = '0%';
      }

      this.roundedValue = Math.round(this.value) + '%';
      
      const mobile = window.matchMedia('(max-width: 767px)');
      const tablet = window.matchMedia(
        '(min-width: 768px) and (max-width: 1199px'
      );

      if (mobile.matches) {
        this.valuePosition = this.value - 20 + '%';
        return;
      }

      if (tablet.matches) {
        this.valuePosition = this.value - 10 + '%'
        return;
      }

      this.valuePosition = this.value - 7 + '%';
    },
  },
};
</script>

<style lang="scss">
$progress-bar-bg: linear-gradient(
  90deg,
  #950e0e 2.96%,
  #ffb99b 33.2%,
  #eddc82 70.43%,
  #99c998 94.07%
);
$text-color: #585757;

.score-wrapper {
  font-family: 'Montserrat', sans-serif;
  justify-content: center;

  // The bar itself
  .score-container {
    height: 0.5rem;
    background: $progress-bar-bg;
    width: 20rem;
  }

  // Percentage value + arrow
  .score-value {
    color: $text-color;
    transform: translateY(1rem);
    p {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
      padding-left: 1.8rem;
      line-height: 0.8;
    }
    span {
      font-size: 0.6rem;
      padding-left: 0.8rem;
    }
    .score-triangle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1rem 0.6rem 0 0.6rem;
      border-radius: 0.2rem;
      border-color: $text-color transparent transparent transparent;
      margin-left: 2rem;
      transform: translateY(0.5rem);
    }
  }

  // Fixed percentages
  .score {
    padding: 5px 7px;
    border: 1px solid $text-color;
    border-radius: 3px;
    background-color: white;
    font-size: 0.6rem;
    position: relative;
    top: 13px;
    &.score-50 {
      left: 35%;
    }
    &.score-100 {
      left: 68%;
    }
  }

  // Circular/not circular texts
  .score-text {
    position: relative;
    top: 0.5rem;
    font-size: 0.8rem;
    &.score-text-0 {
      left: -5%;
    }
    &.score-text-100 {
      left: 63%;
    }
  }
}

@include bp-up(sm) {
  .score-wrapper {
    .score {
      &.score-50 {
        left: 35%;
      }
      &.score-100 {
        left: 70%;
      }
    }
  }
}

@include bp-up(md) {
  .score-wrapper {
    .score-container {
      width: 25rem;
    }
    .score-value {
      transform: translateY(0.8rem);
      min-width: 10rem;
      p {
        font-size: 1.5rem;
        padding: 0rem 1.5rem;
      }
      span {
        font-size: 12px;
      }
      .score-triangle {
        border-width: 1.2rem 0.75rem 0 0.75rem;
        transform: translateY(0.7rem);
      }
    }
    .score {
      &.score-50 {
        left: 42%;
      }
      &.score-100 {
        left: 80%;
      }
    }
    .score-text {
      font-size: 0.8rem;
      &.score-text-0 {
        left: -5%;
      }
      &.score-text-100 {
        left: 77%;
      }
    }
  }
}

@include bp-up(xl) {
  .score-wrapper {
    .score {
      &.score-100 {
        left: 83%;
      }
    }
    .score-text {
      &.score-text-100 {
        left: 80%;
      }
    }
  }
}
</style>
