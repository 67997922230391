export default {
  group: 'Chemical Content & Recycling',
  key: 'chemical_content_recycling',
  chartGroup: 'Chemical & Recycling',
  questions: [
    {
      key: 'easy_recycling',
      label: 'Can the product be easily recycled?',
      required: true,
      type: 'select',
      index: 0,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'crm_recovery',
      label: 'Can CRM be recovered?',
      required: true,
      type: 'select',
      index: 1,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'information_of_hazardous_chemicals',
      label: 'Is product RoHS compliant?',
      required: true,
      type: 'select',
      index: 2,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'information_on_recycled_content',
      label: 'Is the information on recycled material provided?',
      required: false,
      type: 'select',
      index: 3,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'information_on_crm_content',
      label: 'Is the information on CRM content easily available?',
      required: false,
      type: 'select',
      index: 4,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'information_on_recycled_crm_content',
      label: 'Does the contract recycler recycle CRM?',
      required: true,
      type: 'select',
      index: 5,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'product_plastic_content_disclosure',
      label: 'Is full disclosure on the products plastic content provided?',
      required: true,
      type: 'select',
      index: 6,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'presence_of_pops',
      label: 'Are POPs present?',
      required: true,
      type: 'select',
      index: 7,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
  ],
};
