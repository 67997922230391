export default {
  group: 'CE & Environmental Considerations',
  key: 'environmental_consideration',
  chartGroup: 'CE & Environment',
  questions: [
    {
      key: 'manufacturer_recommended_refresh_rate',
      label: 'What is the recommended Product Refresh Rate?',
      required: false,
      type: 'select',
      index: 0,
      options: [
        { value: '>5_years', label: 'More than 5 years' },
        { value: '5_years', label: '5 years' },
        { value: '4_years', label: '4 years' },
        { value: '3_years', label: '3 years' },
        { value: '0', label: '2 years' },
      ],
    },
    {
      key: 'availability_of_spare_parts_end_of_sale',
      label: 'Will spare parts be available after the End of Sale?',
      required: false,
      type: 'select',
      index: 1,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'assessment_of_embodied_carbon_footprint',
      label: 'Has the embodied CO2 assessment been carried out on the product?',
      required: false,
      type: 'select',
      index: 2,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
  ],
};
