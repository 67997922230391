const ranges = {
  locally_sourced_recycled_raw_materials: [
    {
      value: '0_5',
      min: 0,
      max: 5,
    },
    {
      value: '6_30',
      min: 6,
      max: 30,
    },
    {
      value: '30_60',
      min: 30,
      max: 60,
    },
    {
      value: '60_90',
      min: 60,
      max: 90,
    },
    {
      value: '90_100',
      min: 90,
      max: 100,
    },
  ],
  imported_recycled_raw_materials: [
    {
      value: '0_0',
      min: 0,
      max: 0,
    },
    {
      value: '1_30',
      min: 1,
      max: 30,
    },
    {
      value: '30_50',
      min: 30,
      max: 50,
    },
    {
      value: '50_100',
      min: 50,
      max: 100,
    }
  ]
};

export default function getTrackingOptions(key) {
  let options = [];
  
  for (let i = 0; i <= 100; i++) {
    const range = ranges[key].find((range) => range.min <= i && range.max >= i);
    options.push({
      value: range.value + `_${i}`,
      label: `${i}%`,
      api_value: range.value,
    });
  }

  return options.reverse();
}
