<template>
  <div class="row">
    <div class="col-12 col-lg-7 chart-col">
      <EvaluateChart :groups="chartGroups" :data="chartData" />
      <OverallScore :value="overallScore" />
    </div>
    <div class="col-12 col-lg-5">
      <swiper
        :style="{
          '--swiper-navigation-color': '#585757',
          '--swiper-pagination-color': '#585757',
        }"
        :slides-per-view="1"
        :keyboard="{
          enabled: true,
        }"
        :loop="true"
        :navigation="true"
        :pagination="{ clickable: true }"
        :allowTouchMove="false"
      >
        <swiper-slide v-for="item in groups" :key="item">
          <slide-item :item="item" @getResult="getResults" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import dot from 'dot-get';
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import evaluate from '@/utils/data/evaluate';
import OverallScore from '@/components/evaluate/OverallScore';
import SlideItem from '@/components/evaluate/SlideItem';
import EvaluateChart from '@/components/evaluate/EvaluateChart';

SwiperCore.use([Navigation, Pagination, Keyboard]);

export default {
  name: 'EdgEvaluator',
  components: {
    OverallScore,
    SlideItem,
    EvaluateChart,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      groups: evaluate,
      chartGroups: [],
      chartData: {},
      overallScore: 0,
      request: {},
    };
  },
  created() {
    this.chartGroups = evaluate.map((group) => group.chartGroup);
  },
  methods: {
    async getResults(question) {
      const request = this.formatRequest(question);
      const response = await this.$store.dispatch('getEvaluateData', request);
      this.setResults(question, response);
    },
    setResults(question, response) {
      let groupScore = Math.round(dot.get(response, `${question.group}`), 2);
      if (groupScore >= 100) groupScore = 100;

      this.overallScore = response.overall ?? 0;
      this.chartData = {
        chartGroup: question.chartGroup,
        groupScore: groupScore ?? 0,
      };
    },
    formatRequest(question) {
      let request = this.request;

      if (question.group === 'power' && question.key === 'min_power_factor') {
      dot.set(
        request,
        `${question.group}.${question.subgroup}.${question.key}.load_50_percent`,
        `${question.value}`
      );

      return request;
    }

      switch (question.group) {
        case 'power':
          dot.set(
            request,
            `${question.group}.${question.subgroup}.${question.key}`,
            `${question.value}`
          );
          break;
        case 'design_and_manufacture':
        case 'product_disassembly':
          if (!question.subgroup)
            dot.set(
              request,
              `${question.group}.${question.key}`,
              `${question.value}`
            );
          else
            dot.set(
              request,
              `${question.group}.${question.key}.${question.subgroup}`,
              `${question.value}`
            );
          break;
        default:
          dot.set(
            request,
            `${question.group}.${question.key}`,
            `${question.value}`
          );
          break;
      }
      return request;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 38rem;
  padding-bottom: 7rem;
}
.chart-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}
</style>
