<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 header-col">
        <h1 class="title-w1">Ecodesign Evaluator</h1>
        <app-info title="Ecodesign Evaluator">
          <p>
            The Ecodesign Evaluator is a set of various
            <span class="text-bold">Ecodesign Criteria</span>
            consolidated in one place, making it much easier for the designers
            to follow. The tool includes Ecodesign guidelines outlined by the
            COMMISSION REGULATION (EU) 2019/424, the EU Circular Economy Action
            Plan 2020 and bespoke Ecodesign criteria based on the empirical data
            collected by CEDaCI.
          </p>
          <p>
            <span class="text-bold">Check</span> the overall circularity of your
            server design by
            <span class="text-bold">answering questions</span> about various
            criteria considered important for circularity.
          </p>
          <p>
            Note
            <span class="text-bold text-red"
              >that fields marked with an asterisk (*) are mandatory. </span
            >These requirements are determined by the COMMISSION REGULATION (EU) 2019/424 and the EU Circular Economy Action Plan 2020.
          </p>
        </app-info>
      </div>
    </div>
    <EdgEvaluator />
  </div>
</template>

<script>
import EdgEvaluator from '@/components/evaluate/EdgEvaluator';
export default {
  name: 'Evaluate',
  components: {
    EdgEvaluator,
  },
};
</script>

<style lang="scss">
.header-col {
  display: flex;
  margin-bottom: 1rem;
}
</style>
