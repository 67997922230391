<template>
  <div class="card slide-card">
    <div class="card-body">
      <h3 class="card-title text-center">{{ item.group }}</h3>
      <div v-for="question in item.questions" :key="question.key">
        <p class="question-label" :class="{ 'is-required': question.required }">
          {{ question.label }}<span v-if="question.required">*</span>
          <el-popover
            v-if="question.showTooltip"
            placement="top"
            :width="300"
            trigger="hover"
          >
            <template #reference>
              <span class="ifs-1 cursor-pointer"><i class="bi bi-info-circle ms-2 color-default"></i> </span>
            </template>
            <app-definition :definition="question.tooltipText" />
          </el-popover>
        </p>
        <div class="question-item" v-if="question.type === 'select'">
          <select-item :question="question" @selected="handleChange" />
        </div>
        <template v-if="question.type === 'radio'">
          <div
            class="row radio-row"
            v-for="sub in question.sub_questions"
            :key="sub.key"
          >
            <radio-item
              :questionKey="question.key"
              :question="sub"
              @selected="handleChange"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SelectItem from '@/components/evaluate/SelectItem';
import RadioItem from '@/components/evaluate/RadioItem';
export default {
  name: 'SlideItem',
  props: ['item'],
  emits: ['getResult'],
  components: {
    RadioItem,
    SelectItem,
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    handleChange(question) {
      this.$emit('getResult', {
        ...question,
        group: this.item.key,
        chartGroup: this.item.chartGroup,
      });
    },
  },
};
</script>

<style lang="scss">
.slide-card {
  height: 30rem;
  padding-bottom: 1rem;
  .card-title {
    color: $primary;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
    font-size: 14px;
  }
  .is-required {
    color: $red;
  }
  .question-label {
    font-size: 14px;
    margin-top: 0.5rem;
    font-family: $accent-font;
  }
  .el-select {
    width: 90%;
    margin-bottom: 1rem;
  }
  .card-body {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
    }
  }
  .question-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.radio-row {
  display: flex;
  align-items: center;
  p {
    width: 50%;
    margin-bottom: 0;
  }
}
</style>
