import getTrackingOptions from './getTrackingOptions';

export default {
  group: 'Resource Tracking & Tracing',
  key: 'resource_tracking_tracing',
  chartGroup: 'Tracking & Tracing',
  questions: [
    {
      key: 'country_of_product_origin',
      label: 'Is information on country of origin available?',
      required: false,
      type: 'select',
      index: 0,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'source_of_materials',
      label: 'Is the source of materials information readily available?',
      required: false,
      type: 'select',
      index: 1,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'easy_crm_tracking',
      label: 'Can product CRM content be easily tracked?',
      required: false,
      type: 'select',
      index: 2,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'tracking_crm_recycling',
      label: 'Can CRM recycling be tracked?',
      required: false,
      type: 'select',
      index: 3,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'integration_of_locally_sourced_refurbished_parts',
      label:
        'Are there locally sourced refurbished parts used in product design?',
      required: false,
      type: 'select',
      index: 4,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'integration_of_imported_refurbished_parts',
      label: 'Are there imported refurbished parts used in product design?',
      required: false,
      type: 'select',
      index: 5,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'locally_sourced_recycled_raw_materials',
      label: 'What percentage of raw material is locally sourced?',
      required: false,
      type: 'select',
      index: 6,
      options: getTrackingOptions('locally_sourced_recycled_raw_materials'),
      api_values: true,
      filterable: true
    },
    {
      key: 'imported_recycled_raw_materials',
      label: 'What percentage of raw material content is imported?',
      required: false,
      type: 'select',
      index: 7,
      options: getTrackingOptions('imported_recycled_raw_materials'),
      api_values: true,
      filterable: true
    },
    {
      key: 'materials_parts_with_unknown_origin',
      label: 'Are there materials and parts of unknown origin?',
      required: false,
      type: 'select',
      index: 8,
      options: [
        { value: '1_100', label: 'Yes' },
        { value: '0_0', label: 'No' },
      ],
    },
  ],
};
