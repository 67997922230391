export default {
  group: 'Product Disassembly',
  key: 'product_disassembly',
  chartGroup: 'Product Disassembly',
  questions: [
    {
      key: 'disassembly_for_repairing_components',
      label:
        'Can the following components be easily removed for disassembly and repair?',
      type: 'radio',
      required: true,
      sub_questions: [
        {
          label: 'Data Storage',
          key: 'storage',
          index: 0,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Memory',
          key: 'memory',
          index: 1,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'CPU',
          key: 'cpu',
          index: 2,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Motherboard',
          key: 'motherboard',
          index: 3,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Expansion/Graphics Card',
          key: 'expansion',
          index: 4,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'PSU',
          key: 'psu',
          index: 5,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Chassis',
          key: 'chasis',
          index: 6,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Batteries',
          key: 'battery',
          index: 7,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
        {
          label: 'Cooling Assemblies',
          key: 'cooling_assembly',
          index: 8,
          options: [
            { value: 'available', label: 'Yes' },
            { value: 'not_available', label: 'No' },
          ],
        },
      ],
    },
  ],
};
