import power from '@/utils/data/evaluate/power';
import security from '@/utils/data/evaluate/security';
import software from '@/utils/data/evaluate/software';
import product from '@/utils/data/evaluate/product';
import instructions from '@/utils/data/evaluate/instructions';
import disassembly from '@/utils/data/evaluate/disassembly';
import design from '@/utils/data/evaluate/design';
import recycling from '@/utils/data/evaluate/recycling';
import tracking from '@/utils/data/evaluate/tracking';
import environment from '@/utils/data/evaluate/environment';

const evaluate = [
  power,
  security,
  software,
  product,
  instructions,
  disassembly,
  design,
  recycling,
  tracking,
  environment,
];

export default evaluate;
