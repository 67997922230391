export default {
  group: 'Software & Firmware',
  key: 'software_firmware',
  chartGroup: 'Software & Firmware',
  questions: [
    {
      key: 'free_firmware_updates_market_entry',
      required: true,
      index: 0,
      label:
        'How long are FRAND firmware updates available for from the market entry point?',
      type: 'select',
      options: [
        { value: 'more_than_2_years', label: 'More than 2 years' },
        { value: 'only_2_years', label: '2 years' },
        { value: '0', label: 'Less than 2 years' },
      ],
      showTooltip: true,
      tooltipText: {
        acronym: 'FRAND',
        text: 'Fair, Reasonable and Non-Discriminatory',
      }
    },
    {
      key: 'free_firmware_updates_end_of_sale',
      label:
        'What is the availability of FRAND firmware updates at the end of sale?',
      required: true,
      index: 1,
      type: 'select',
      options: [
        { value: 'more_than_8_years', label: 'More than 8 years' },
        { value: 'only_8_years', label: '8 years' },
        { value: '0', label: 'Less than 8 years' },
      ],
      showTooltip: true,
      tooltipText: {
        acronym: 'FRAND',
        text: 'Fair, Reasonable and Non-Discriminatory',
      }
    },
    {
      key: 'open_source_updates_for_obsolete',
      required: false,
      label: 'Are there open source options for updates/upgrades?',
      type: 'select',
      index: 2,
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'security_updates_end_of_sale',
      label:
        'What is the availability of security updates after the end of sale?',
      index: 3,
      required: true,
      type: 'select',
      options: [
        { value: 'more_than_8_years', label: 'More than 8 years' },
        { value: 'only_8_years', label: '8 years' },
        { value: 'less_than_8_years', label: 'Less than 8 years' },
      ],
    },
  ],
};
