<template>
  <p class="radio-label">{{ question.label }}</p>
  <el-radio-group v-model="radio">
    <el-radio
      v-for="option in question.options"
      :key="option.value"
      @change="handleChange(option.value)"
      :label="option.label"
      >{{ option.label }}</el-radio
    >
  </el-radio-group>
</template>

<script>
export default {
  name: 'RadioItem',
  props: ['question', 'questionKey'],
  emits: ['selected'],
  data() {
    return {
      radio: null,
    };
  },
  methods: {
    handleChange(value) {
      this.$emit('selected', {
        key: this.questionKey,
        subgroup: this.question.key,
        value: value,
        index: this.question.index,
      });
    },
  },
};
</script>

<style lang="scss">
.el-radio-group {
  width: 50%;
}
.radio-label {
  width: 50%;
  margin-bottom: 0;
  font-size: 14px;
}
</style>
