export default {
  group: 'Availability of Product Specific Info',
  sub: '(from the market entry point and for minimum of 8 Years from the End of Sale)',
  key: 'product',
  chartGroup: 'Product Info',
  questions: [
    {
      key: 'product_information',
      index: 0,
      label:
        'What is the availability period for following product specific information? Product Type, OEM Details, Model Number, PSU Efficiency, Power Factor at 50%, ASHRAE Class, Data Sanitisation Instructions/tool/standards.',
      required: true,
      type: 'select',
      options: [
        { value: 'more_than_8_years', label: 'More than 8 years' },
        { value: 'only_8_years', label: '8 years' },
        { value: '0', label: 'Less than 8 years' },
      ],
    },
    {
      key: 'recycler_info_end_of_sale',
      index: 1,
      label:
        'How long is recycler specific information available for after the end if sale?',
      required: true,
      type: 'select',
      options: [
        { value: 'more_than_8_years', label: 'More than 8 years' },
        { value: 'only_8_years', label: '8 years' },
        { value: '0', label: 'Less than 8 years' },
      ],
    },
    {
      key: 'indicative_weight_range_cobalt',
      index: 2,
      label:
        'Is recycler specfic information available for the indicitive weight range of cobalt batteries?',
      required: true,
      type: 'select',
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'indicative_weight_range_neodymium',
      index: 3,
      label:
        'Is recycler specfic information point available for the indicitive weight range of neyodymium in HDDs?',
      required: true,
      type: 'select',
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
    {
      key: 'digital_product_information',
      index: 4,
      label: 'Does the product have a searchable digital ID?',
      required: true,
      type: 'select',
      options: [
        { value: 'available', label: 'Yes' },
        { value: 'not_available', label: 'No' },
      ],
    },
  ],
};
