<template>
  <el-select
    v-model="selected"
    placeholder="Select"
    v-if="question.type === 'select'"
    @change="handleChange"
    clearable
    :filterable="question.filterable"
    :filter-method="filterMethod"
  >
    <el-option
      v-for="option in filteredOptions"
      :key="option.label"
      :label="option.label"
      :value="option.value"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectItem',
  props: ['question'],
  emits: ['selected'],
  data() {
    return {
      selected: null,
      filteredOptions: [],
    };
  },
  created() {
    this.filteredOptions = this.question.options;
  },
  methods: {
    handleChange() {
      const selectedVal =
        this.question?.api_values && this.selected
          ? this.question.options.find(
              (option) => option.value === this.selected
            ).api_value
          : this.selected;

      this.$emit('selected', {
        key: this.question.key,
        subgroup: this.question.subgroup,
        value: selectedVal,
        index: this.question.index,
      });
    },
    filterMethod(query) {
      this.filteredOptions = this.question.options.filter((option) =>
        option.label.startsWith(query)
      );
    },
  },
};
</script>
